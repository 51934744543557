





























































































































































































import {
  defineComponent,
  onMounted,
  provide,
  Ref,
  ref,
  nextTick,
  watch,
  PropType,
  useFetch,
  computed,
  onActivated,
} from "@nuxtjs/composition-api";
import {
  SfAccordion,
  SfButton,
  SfFilter,
  SfHeading,
  SfRadio,
  SfSelect,
  SfDropdown,
  SfList,
  SfDivider,
} from "@storefront-ui/vue";
import FtSidebar from "~/components/customSFUI/vue/src/components/organisms/FtSidebar/FtSidebar.vue";
import FtAccordion from "~/components/customSFUI/vue/src/components/organisms/FtAccordion/FtAccordion.vue";

import { clearAllBodyScrollLocks } from "body-scroll-lock";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import { useUiHelpers } from "~/composables";
import {
  getFilterConfig,
  isFilterEnabled,
} from "~/modules/catalog/category/config/FiltersConfig";
import SelectedFilters from "~/modules/catalog/category/components/filters/FiltersSidebar/SelectedFilters.vue";
import { getProductFilterByCategoryCommand } from "~/modules/catalog/category/components/filters/command/getProductFilterByCategoryCommand";
import { getProductFilterBySearchCommand } from "~/modules/catalog/category/components/filters/command/getProductFilterBySearchCommand";

import type { Aggregation } from "~/modules/GraphQL/types";
import type { SelectedFiltersInterface } from "./useFilters";
import { useFilters } from "./useFilters";
import { SortingModel } from "~/modules/catalog/category/composables/useFacet/sortingOptions";
import useFtGetCategoryPrice from '~/composables/FortyTwo/useFtGetCategoryPrice';
import CategoryTopFilters  from "~/modules/catalog/category/components/filters/CategoryTopFilters.vue";
import CategoryFilterButton from "~/components/customSFUI/vue/src/components/atoms/CategoryFilterButton/CategoryFilterButton.vue";
import { merge } from 'lodash-es';
export interface UseFiltersProviderInterface {
  selectedFilters: Ref<SelectedFiltersInterface>;
  filters: Ref<Aggregation[]>;
}

export default defineComponent({
  name: "CategoryFilters",
  components: {
    SelectedFilters,
    SkeletonLoader,
    CheckboxType: () =>
      import(
        "~/modules/catalog/category/components/filters/renderer/CheckboxType.vue"
      ),
    SwatchColorType: () =>
      import(
        "~/modules/catalog/category/components/filters/renderer/SwatchColorType.vue"
      ),
    RadioType: () =>
      import(
        "~/modules/catalog/category/components/filters/renderer/RadioType.vue"
      ),
    YesNoType: () =>
      import(
        "~/modules/catalog/category/components/filters/renderer/YesNoType.vue"
      ),
    FtSidebar,
    FtAccordion,
    SfHeading,
    SfAccordion,
    SfFilter,
    SfButton,
    SfRadio,
    SfSelect,
    SfDropdown,
    SfList,
    SfDivider,
    CategoryTopFilters,
    CategoryFilterButton
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    isFilterColumnOpen: {
      type: Boolean,
      default: false,
    },
    catUid: {
      type: String,
      required: true,
    },
    sortBy: {
      type: Object as PropType<SortingModel>,
      required: true,
    },
    filtertype: {
      type: String,
      required: true,
    },
    sharedFilters: {
      type: Object,
    },
    filters: {
      type: Array as PropType<Aggregation[]>,
      default: [],
    },
  },
  setup(props, { emit }) {
    const {
      selectedFilters,
      selectFilter,
      removeFilter,
      isFilterSelected,
      getRemovableFilters,
    } = useFilters();

    const { changeFilters, clearFilters, clearSearchPageFilters } = useUiHelpers();
    const removableFilters = ref([]);
    const isLoading = ref(true);
    const uiHelpers = useUiHelpers();
    const categoryMinPrice = ref(0);
    const categoryMaxPrice = ref(1000);
    const { getCategoryPrice } = useFtGetCategoryPrice();

    const doChangeSorting = (sort: string) => {
      uiHelpers.changeSorting(sort, false);
      emit("reloadProducts");
    };

    const doRemoveFilter = ({ id, value }: { id: string; value: string }) => {
      removeFilter(id, value);
      changeFilters(selectedFilters.value, false);
      updateRemovableFilters();
      emit("sharedFilterUpdate", selectedFilters.value);
      emit("reloadProducts");
      emit("close");
    };

    const updateRemovableFilters = () => {
      removableFilters.value = getRemovableFilters(
        props.filters,
        selectedFilters.value
      );
    };

    const doApplyFilters = () => {
      changeFilters(selectedFilters.value, false);
      updateRemovableFilters();
      if (window?.scroll) {
        window.scroll(0, 0);
      }
      emit("sharedFilterUpdate", selectedFilters.value);
      emit("reloadProducts");
      emit("close");
    };

    const doClearFilters = () => {
      if(props.filtertype === "category"){
        clearFilters(false);
        selectedFilters.value = {};
      } else {
        clearSearchPageFilters(false);
        Object.keys(selectedFilters.value).forEach((filter) => {
          if(filter !== 'q') {
            Reflect.deleteProperty(selectedFilters.value, filter);
          }
        });
      }

      updateRemovableFilters();
      emit("sharedFilterUpdate", selectedFilters.value);
      emit("reloadProducts");
      emit("close");
    };

    watch(
      () => props.isVisible,
      (newValue) => {
        // disable Storefrontt UI's body scroll lock which is launched when :visible prop on SfSidebar changes
        // two next ticks because SfSidebar uses nextTick aswell, and we want to do something after that tick.
        if (newValue) {
          nextTick(() => nextTick(() => clearAllBodyScrollLocks()));
        }
      }
    );

    watch(
       () => props.sharedFilters,
       (newValue) => {
        selectedFilters.value = {};
        selectedFilters.value = merge({}, selectedFilters.value, newValue);
        updateRemovableFilters();
      },
      {deep: true, immediate: true}
    );

    const fetchCategoryPrice = async (category_id) => {
      const { data } = await getCategoryPrice(category_id);
      categoryMinPrice.value = Math.trunc(data.getCategoryPrice.min_price);
      categoryMaxPrice.value = Math.trunc(data.getCategoryPrice.max_price);
      emit("updCategoryMinPrice", categoryMinPrice.value);
      emit("updCategoryMaxPrice", categoryMaxPrice.value);
    };

    useFetch(async () => {
      if(props.filtertype === "category"){
         await fetchCategoryPrice(props.catUid);
      }
      
      updateRemovableFilters();
      isLoading.value = false;
    });

    onMounted(() => {
      emit("updCategoryMinPrice", categoryMinPrice.value);
      emit("updCategoryMaxPrice", categoryMaxPrice.value);
    });

    onActivated(() => {
      if (Object.keys(uiHelpers.getFiltersDataFromUrl(true)).length > 0) {
        if (JSON.stringify(uiHelpers.getFiltersDataFromUrl(true)) !== JSON.stringify(selectedFilters.value)) {
          // Reset selectedFilters when URL filters data and selectedFilters data are different
          selectedFilters.value = {};
          selectedFilters.value = merge({}, selectedFilters.value, uiHelpers.getFiltersDataFromUrl(true));
          updateRemovableFilters();
          emit("sharedFilterUpdate", selectedFilters.value);
          emit("reloadProducts");
          emit("close");
        }
      } else if (Object.keys(uiHelpers.getFiltersDataFromUrl(true)).length === 0 && Object.keys(selectedFilters.value).length > 0) {
        // Clear filters when URL dont have filters data but selectedFilters still have data
        doClearFilters();
      }
    });

    provide("UseFiltersProvider", {
      isFilterSelected,
      selectedFilters,
      filters: props.filters,
    });

    const isActiveFastDelivery = computed(() => props.sortBy.selected == "calculated_lead_time_ASC" ? true : false);

    const fastDeliverySorting = (isFastDelivery: boolean) => {
      const sort = isFastDelivery ? "position_DESC" : "calculated_lead_time_ASC";
      uiHelpers.changeSorting(sort,false);
      emit("reloadProducts");
    };

    return {
      selectFilter,
      doApplyFilters,
      doRemoveFilter,
      doClearFilters,
      doChangeSorting,
      getFilterConfig,
      selectedFilters,
      isLoading,
      removableFilters,
      categoryMinPrice,
      categoryMaxPrice,
      fastDeliverySorting,
      isActiveFastDelivery
    };
  },
});
